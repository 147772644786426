<template>
  <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-6 text-xs sm:text-sm">
    <n-form-textarea
      :label="`${$t('form.report_theft_timing.label')}*`"
      id="input-description-timing"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="timing"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
    />
    <n-form-textarea
      :label="`${$t('form.report_theft_location.label')}*`"
      id="input-description-location"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="location"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
    />
    <n-form-textarea
      :label="`${$t('form.report_theft_circumstances.label')}*`"
      id="input-description-circumstances"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="circumstances"
      field_type="textarea"
      rows="5"
      required
      no-resize
      maxlength="250"
    />
    <n-form-textarea
      :label="$t('form.report_theft_culprit_description.label')"
      id="input-description-culprit"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="culprit_description"
      field_type="textarea"
      rows="5"
      no-resize
      maxlength="250"
    />
    <n-form-textarea
      :label="$t('form.report_theft_witness_contacts.label')"
      id="input-description-witnesses"
      :placeholder="$t('form.common.placeholders.explain')"
      v-model="witness_contacts"
      field_type="textarea"
      rows="5"
      no-resize
      maxlength="250"
    />
  </div>
</template>

<script>
export default {
  name: "components.report.theft.step-date",
  data: () => ({
    timing: "",
    location: "",
    circumstances: "",
    culprit_description: "",
    witness_contacts: ""
  }),
  computed: {
    dataValid() {
      return !!this.timing && !!this.location && !!this.circumstances;
    }
  },
  methods: {
    persistData() {
      this.$parent.$parent.$emit("dataChange", {
        timing: this.timing,
        location: this.location,
        circumstances: this.circumstances,
        culprit_description: this.culprit_description,
        witness_contacts: this.witness_contacts
      });
      this.$parent.$parent.$emit("pickedChange", "");
    },
    resetData() {
      this.$parent.$parent.$emit("dataChange", {});
      this.$parent.$parent.$emit("pickedChange", "");
    }
  },
  watch: {
    timing() {
      if (this.dataValid) {
        this.persistData();
      } else {
        this.resetData();
      }
    },
    location() {
      if (this.dataValid) {
        this.persistData();
      } else {
        this.resetData();
      }
    },
    circumstances() {
      if (this.dataValid) {
        this.persistData();
      } else {
        this.resetData();
      }
    },
    culprit_description() {
      if (this.dataValid) {
        this.persistData();
      } else {
        this.resetData();
      }
    },
    witness_contacts() {
      if (this.dataValid) {
        this.persistData();
      } else {
        this.resetData();
      }
    }
  }
};
</script>
