<template>
  <div>
    <p>
      {{ subtitle }}
    </p>
    <n-button-regular @click.native="accept" class="mt-6">
      OK
    </n-button-regular>
  </div>
</template>

<script>
export default {
  name: "components.report.step-message",
  props: {
    subtitle: String
  },
  methods: {
    accept() {
      this.$parent.$parent.$emit("dataChange", { accepted: true });
      this.$parent.$parent.$emit("pickedChange", "Ok");
      this.$parent.$parent.setCollapsed(true);
    }
  }
};
</script>
