<template>
  <div class="w-full">
    <p class="font-semibold mb-2">
      <n-html :text="$t('form.report_theft_statement.label')" />
    </p>
    <div
      class="relative border border-dashed border-brand-purple-500 bg-brand-purple-100/25 cursor-pointer"
    >
      <p
        class="hidden lg:block absolute pl-6 top-1/2 -translate-y-1/2 left-0 right-1/3 text-left align-middle text-brand-purple-500 "
      >
        <n-html :text="$t('form.report_theft_statement.placeholder')" />
      </p>
      <n-button-regular
        @click.native="close"
        class="absolute left-0 lg:left-auto lg:right-0 top-1/2 -translate-y-1/2 mx-6 text-brand-purple-500"
      >
        <n-html :text="$t('form.report_theft_statement.button')" />
      </n-button-regular>
      <input
        type="file"
        class="opacity-0 h-20 w-full cursor-pointer"
        multiple
        @change="onChange"
        accept="image/*"
      />
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-2 gap-x-6 py-2">
      <div
        class="flex justify-between bg-brand-gray-100 text-xs text-brand-gray-400 px-3 py-1"
        v-for="file in files"
        :key="file.name"
      >
        {{ file.name }}
        <n-icon
          xs
          name="Delete"
          class="cursor-pointer"
          @click.native="removeFile(index)"
        />
      </div>
    </div>
    <!--    <p>-->
    <!--      <n-html :text='$t("form.report_theft_statement.label")' />-->
    <!--    </p>-->
    <!--    <n-form-group id="input-group-area-files" class="margin-bottom-none">-->
    <!--      <b-form-file-->
    <!--        multiple-->
    <!--        id="input-area-files"-->
    <!--        accept="image/jpeg, image/png"-->
    <!--        :placeholder="$t('form.report_theft_statement.placeholder')"-->
    <!--        :browse-text="$t('form.report_theft_statement.button')"-->
    <!--        v-model="uploaded_files"-->
    <!--        v-on:input="onChange"-->
    <!--      ></b-form-file>-->
    <!--      <b-list-group class="mt-4" v-for="(file, index) in files" :key="file.name">-->
    <!--        <b-list-group-item class="d-flex justify-content-between align-items-center">-->
    <!--          {{ file.name }}-->
    <!--          <b-button variant="danger" @click="removeFile(index)" large><n-html :text='$t("common.remove")' /></b-button>-->
    <!--        </b-list-group-item>-->
    <!--      </b-list-group>-->
    <!--    </n-form-group>-->

    <div class="flex justify-end">
      <n-button-regular
        @click.native="nextStep"
        :disabled="!dataValid"
        class="w-full sm:w-max mt-4"
      >
        <n-html :text="$t('common.next')" />
      </n-button-regular>
    </div>
    <!--    <button @click="nextStep" :disabled="!dataValid" class="btn btn-primary ml-auto">-->
    <!--      {{$t("common.next")}}-->
    <!--    </button>-->
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "components.report.theft.step-statement-file",
  data: () => ({
    uploaded_files: [],
    files: []
  }),
  computed: {
    dataValid() {
      return !!this.files.length;
    }
  },
  methods: {
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        this.uploaded_files = files;
      }
      this.uploaded_files.forEach(thisFile => {
        this.files.push({ name: thisFile.name, file: thisFile });
      });
      this.uploaded_files = [];
    },
    removeFile(i) {
      this.files.splice(i, 1);
    },
    persistData() {
      let files = [];
      this.files.forEach(file => {
        files.push(file.file);
      });
      let filenames = [];
      files.forEach(f => filenames.push(f.name));
      filenames = filenames.join(", ");
      this.$parent.$parent.$emit("dataChange", { files: files });
      this.$parent.$parent.$emit("pickedChange", _(filenames).truncate(20));
    },
    resetData() {
      this.$parent.$parent.$emit("dataChange", {});
      this.$parent.$parent.$emit("pickedChange", "");
    },

    nextStep() {
      if (this.dataValid) {
        this.persistData();
      }
    }
  },
  watch: {
    files() {
      if (this.dataValid) {
        // this.persistData();
      } else {
        this.resetData();
      }
    }
  }
};
</script>
