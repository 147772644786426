<template>
  <div class="max-w-7xl m-auto grid grid-cols-12 grid-rows-1 p-8 px-5 sm:p-8">
    <div
      class="col-span-12 lg:col-span-6 mx-0 mb-1 mt-0 sm:mx-4 sm:mb-4 m-4 sm:mt-6"
    >
      <h1
        class="uppercase text-brand-purple-500 font-serif font-bold text-2xl sm:text-3xl"
      >
        <n-html :text="$t('report.theft.title')" />
      </h1>

      <div class="mt-6" v-if="form_states.form">
        <p class="text-brand-gray-500">
          <n-html :text="$t('report.theft.success.subtitle')" />
        </p>
        <p class="text-brand-gray-500">
          <n-html :text="$t('report.theft.success.number')" />
          <strong> {{ this.claim.navision_ext_id }}</strong>
        </p>
        <p class="text-brand-gray-500">
          <n-html :text="this.claim.customer_notification" />
        </p>
        <n-button-regular
          @click.native="$router.push({ name: 'claims.index' }).catch(() => {})"
          class="mt-6 text-brand-gray-500"
        >
          <n-html :text="$t('report.common.button_to_claim')" />
        </n-button-regular>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-6 m-4 mt-8" v-if="form_states.form">
      <!--      class="flex flex-col fixed inset-0 bg-gray-600 bg-opacity-50 w-full h-full items-center content-center justify-center z-50"-->
      <n-card no-margin>
        <h3 class="text-brand-purple-500 font-serif font-semibold text-xl">
          <n-html :text="$t('report.theft.success.continue.title')" />
        </h3>
        <p class="text-brand-gray-500 mt-6">
          <n-html :text="$t('report.theft.success.continue.subtitle')" />
        </p>
        <ol class="bg-brand-purple-100/25 p-6 mt-6">
          <li
            v-for="(step, index) in continueSteps"
            v-bind:key="step"
            class="flex items-center mb-4"
          >
            <p class="pr-5 text-brand-purple-500">{{ index + 1 }}</p>
            <p>
              <n-html :text="$t(step)" />
            </p>
          </li>
        </ol>
        <p class="text-brand-gray-500 mt-6">
          <n-html :text="$t('report.theft.success.continue.additional')" />
        </p>
        <n-button-link
          bold
          @click.native="
            $router.push({ name: 'service.index' }).catch(() => {})
          "
          class="flex items-center mt-6"
        >
          <n-html :text="$t('report.common.link_to_customer_service')" />
          <n-icon name="Chevron-right" small></n-icon>
        </n-button-link>
      </n-card>
      <!--        <b-alert show variant="primary" v-if="!this.contracts.length">-->
      <!--          <n-html :text='$t("report.common.no_contracts")' />-->
      <!--        </b-alert>-->
    </div>

    <!--      This should be an error-->
    <div class="col-span-12" v-if="!this.contracts.length">
      <n-html :text="$t('report.common.no_contracts')" />
    </div>

    <div
      class="col-span-12 m-4 mx-0 sm:mx-4 mt-0 sm:mt-6"
      v-if="!form_states.form && this.contracts.length"
    >
      <card-step
        ref="step1"
        step="1"
        :title="$t('report.common.personal_information.title')"
        @dataChange="d => (this.step1 = d)"
      >
        <StepPersonalInformation
          ref="step1"
          @dataChange="d => (this.step1 = d)"
        />
      </card-step>

      <card-step
        ref="step2"
        step="2"
        :title="$t('report.common.product.title')"
        @dataChange="d => (this.step2 = d)"
      >
        <StepDevice
          ref="step2"
          claim-type="theft"
          @dataChange="d => (this.step2 = d)"
        />
      </card-step>
      <card-step
        ref="step3"
        step="3"
        manual_close
        :title="$t('report.theft.type.title')"
        @dataChange="d => (this.step3 = d)"
      >
        <StepType claim-type="theft" :contract="this.step2.contract" />
      </card-step>
      <card-step
        ref="step4"
        step="4"
        manual_close
        :title="$t('report.theft.statement.title')"
        @dataChange="d => (this.step4 = d)"
      >
        <StepRequiredQuestion
          :subtitle="$t('report.theft.statement.subtitle')"
          :error="$t('report.theft.statement.error')"
          :no_collapse_on_false="true"
        />
      </card-step>
      <card-step
        ref="step5"
        step="5"
        manual_close
        :title="$t('report.theft.area.title')"
        @dataChange="d => (this.step5 = d)"
      >
        <StepArea
          :contract="this.step2.contract"
          :type="this.step3.type"
          claim-type="theft"
        />
      </card-step>
      <card-step
        ref="step6"
        step="6"
        manual_close
        :title="$t('report.common.date.title')"
        @dataChange="d => (this.step6 = d)"
      >
        <StepDate claim-type="damage" :contract="this.step2.contract" />
      </card-step>
      <card-step
        ref="step7"
        step="7"
        manual_close
        :title="$t('report.theft.sim.title')"
        @dataChange="d => (this.step7 = d)"
      >
        <StepMessage :subtitle="$t('report.theft.sim.subtitle')" />
      </card-step>
      <card-step
        ref="step8"
        step="8"
        :title="$t('report.theft.statement_file.title')"
        @dataChange="d => (this.step8 = d)"
      >
        <StepStatementFile />
      </card-step>
      <card-step
        ref="step9"
        step="9"
        :title="$t('report.theft.description.title')"
        @dataChange="d => (this.step9 = d)"
      >
        <StepDescription />
      </card-step>
      <n-form-group
        id="button-group-claim-create"
        class="mt-4"
        :invalid-feedback="$t('report.theft.create.error')"
        :state="form_states.form"
      >
        <n-button-regular
          class="w-full mt-4 sm:w-max sm:float-right"
          @click.native="show_confirm_modal = true"
          :disabled="!valid_confirm || form_states.loading"
        >
          <n-html :text="$t('report.theft.confirm.button')" />
        </n-button-regular>
      </n-form-group>
    </div>
    <!--    MODAL-->
    <modal
      v-if="show_confirm_modal"
      @close="show_confirm_modal = false"
      :title="$t('report.theft.confirm.title')"
    >
      <template v-slot:default>
        <p class="pb-5 border-b">
          <n-html :text="$t('report.theft.confirm.description')" />
        </p>
        <ConfirmStepInfo
          :title="$t('report.common.product.title')"
          step="step2"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step2.picked"
          type="theft"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.theft.type.title')"
          step="step3"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step3.picked"
          type="theft"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.theft.statement.title')"
          step="step4"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step4.picked"
          type="theft"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.theft.area.title')"
          step="step5"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step5.picked"
          type="theft"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.common.date.title')"
          step="step6"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step6.picked"
          type="theft"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.theft.sim.title')"
          step="step7"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step7.picked"
          type="theft"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.theft.statement_file.title')"
          step="step8"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :value="$refs.step8.picked"
          type="theft"
        ></ConfirmStepInfo>
        <ConfirmStepInfo
          :title="$t('report.theft.description.title')"
          step="step9"
          @edit="
            step => {
              this.$refs[step].setCollapsed(false);
              this.show_confirm_modal = false;
            }
          "
          :values="step9"
          type="theft"
        ></ConfirmStepInfo>
        <p class="mt-5">
          <n-form-group
            id="input-required-question"
            class="margin-bottom-none"
            :invalid-feedback="error"
            :state="state"
          >
            <n-checkbox id="input-easyregister-agreement" v-model="step10">
              <n-html :text="$t('report.theft.truth.title')" />
            </n-checkbox>
          </n-form-group>
        </p>
      </template>
      <template #footer>
        <div />
        <n-button-regular
          @click.native="save"
          :disabled="!valid_send || form_states.loading"
        >
          <n-html :text="$t('report.theft.create.button')" />
        </n-button-regular>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import CardStep from "../../components/report/card-step";
import StepDevice from "../../components/report/step-device";
// import StepType from "../../components/report/theft/step-type";
import StepType from "../../components/report/step-type";
import StepDate from "../../components/report/step-date";
// import StepArea from "../../components/report/theft/step-area";
import StepArea from "../../components/report/step-area";
import StepMessage from "../../components/report/step-message";
import StepPersonalInformation from "../../components/report/step-personal-information";
import StepRequiredQuestion from "../../components/report/step-required-question";
import StepStatementFile from "../../components/report/theft/step-statement-file";
import StepDescription from "../../components/report/theft/step-description";
import Modal from "@/components/theme/modal/modal";
import ConfirmStepInfo from "@/components/report/confirm-step-info";
import ClaimConfiguration from "@/assets/json/claim_configuration.json";
import moment from "moment/moment";
import { uuid } from "vue-uuid";

export default {
  name: "report.theft",
  components: {
    CardStep,
    StepDevice,
    StepType,
    StepDate,
    StepArea,
    StepMessage,
    StepRequiredQuestion,
    StepStatementFile,
    StepDescription,
    StepPersonalInformation,
    Modal,
    ConfirmStepInfo
  },
  data: () => ({
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: {},
    step9: {},
    step10: false,
    show_confirm_modal: false,
    form_states: {
      form: null,
      loading: false
    },
    claim_number: null,
    continueSteps: []
  }),
  computed: {
    ...mapGetters({
      contracts: "contracts/getAllActive"
    }),
    claim() {
      return this.$store.getters["claims/getClaim"];
    },
    showStep1() {
      return _.isEmpty(this.step1);
    },
    showStep2() {
      return !_.isEmpty(this.step1) && _.isEmpty(this.step2);
    },
    showStep3() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        _.isEmpty(this.step3)
      );
    },
    showStep4() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        _.isEmpty(this.step4)
      );
    },
    showStep5() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        this.step4.selected &&
        _.isEmpty(this.step5)
      );
    },
    showStep6() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        this.step4.selected &&
        !_.isEmpty(this.step5) &&
        _.isEmpty(this.step6)
      );
    },
    showStep7() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        this.step4.selected &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        _.isEmpty(this.step7)
      );
    },
    showStep8() {
      console.log(this.step7);
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        this.step4.selected &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        !_.isEmpty(this.step7) &&
        _.isEmpty(this.step8)
      );
    },
    showStep9() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        this.step4.selected &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        !_.isEmpty(this.step7) &&
        !_.isEmpty(this.step8) &&
        _.isEmpty(this.step9)
      );
    },
    valid_confirm() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        this.step4.selected &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        !_.isEmpty(this.step7) &&
        !_.isEmpty(this.step8) &&
        !_.isEmpty(this.step9)
      );
    },
    valid_send() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        this.step4.selected &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6) &&
        !_.isEmpty(this.step7) &&
        !_.isEmpty(this.step8) &&
        !_.isEmpty(this.step9) &&
        this.step10
      );
    }
  },
  watch: {
    showStep1(n) {
      this.$refs.step1.setCollapsed(!n);
    },
    showStep2(n) {
      this.$refs.step2.setCollapsed(!n);
    },
    showStep3(n) {
      this.$refs.step3.setCollapsed(!n);
    },
    showStep4(n) {
      if (_.isEmpty(this.step4)) {
        this.$refs.step4.setCollapsed(!n);
      }
    },
    showStep5(n) {
      if (this.step4.selected) {
        this.$refs.step5.setCollapsed(!n);
      }
    },
    showStep6(n) {
      this.$refs.step6.setCollapsed(!n);
    },
    showStep7(n) {
      this.$refs.step7.setCollapsed(!n);
    },
    showStep8(n) {
      this.$refs.step8.setCollapsed(!n);
    },
    showStep9(n) {
      if (!this.showStep8 && !_.isEmpty(this.step9)) {
        return;
      }
      this.$refs.step9.setCollapsed(!n);
    },
    step2() {
      const _category = ClaimConfiguration.objectCategories.find(
        objectCategory =>
          objectCategory.name === this.step2.contract.category_code
      );

      this.continueSteps = _category.nextSteps["theft"];
    }
  },
  methods: {
    save() {
      this.form_states.form = null;
      this.form_states.loading = true;

      const payload = {
        claim_type: "2",
        contract: this.step2.contract.id,
        description: this.step5.description,
        type: this.step3.type,
        area: this.step5.area,
        date: this.step6.date,
        circumstances_question: this.$i18n.t(
          "form.report_theft_circumstances.label"
        ),
        circumstances: this.step9.circumstances,
        location_question: this.$i18n.t("form.report_theft_location.label"),
        location: this.step9.location,
        timing_question: this.$i18n.t("form.report_theft_timing.label"),
        timing: this.step9.timing,
        find_device: this.step2.find_device,
        report_date: this.format_date(
          moment()
            .toDate()
            .toDateString(),
          "DD-MM-YYYY"
        ),
        claim_unique: uuid.v4()
      };
      if ("culprit" in this.step9) {
        (payload["culprit_question"] = this.$i18n.t(
          "form.report_theft_culprit_description.label"
        )),
          (payload["culprit"] = this.step9.culprit_description);
      }
      if ("witnesses" in this.step9) {
        (payload["witnesses_question"] = this.$i18n.t(
          "form.report_theft_witness_contacts.label"
        )),
          (payload["witnesses"] = this.step9.witness_contacts);
      }

      let formData = new FormData();

      if (this.step8.files && this.step8.length) {
        this.step8.files.forEach((f, i) => {
          formData.append(`file-${i}`, f);
        });
      }

      formData.append("data", JSON.stringify(payload));

      this.$store
        .dispatch("claims/create", formData)
        .then(result => {
          this.claim_number = result.data.value;
          this.form_states.form = true;
          this.form_states.loading = false;
          this.$router.push({
            name: "processing",
            query: {
              claim_type: "2",
              category: this.step2.contract.category_code
            }
          });
        })
        .catch(() => {
          this.form_states.form = false;
          this.form_states.loading = false;
        });
    }
  },
  mounted() {
    this.$refs.step1.setCollapsed(false);
  },
  created() {
    if (this.$route.query.routed) {
      if (this.claim.is_routed) {
        this.form_states.form = true;
        this.form_states.loading = false;

        if (this.claim.on_hold_code === "") {
          this.accepted = true;
        } else {
          this.rejected = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../theme/variables";

.theft-report-success-card-steps li::before {
  content: counter(li);
  color: $color-primary;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.theft-report-success-card-steps li {
  counter-increment: li;
}

.theft-report-success-card-step {
  display: inline-grid;
  padding-left: 1em;
  padding-right: 1em;
}
</style>
